import { addTeamUrl, listTeamUrl,updateTeamUrl, updateOrderUrl, deleteMemberUrl } from '../api/index'
import axios from 'axios'
import { notification } from 'antd'
import { getToken } from '../../../request'


export const addTeamAction = async params => {
  try {
    let { data } = await axios.post(
      addTeamUrl(),
      { ...params },
      getToken()
    )
    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}
export const updateTeamAction = async params => {
  try {
    console.log(params)
    let {data} = await axios.put(
      updateTeamUrl(),
      {...params},
      getToken(),
    )
  
    return data
  } catch (err) {
    notification.error({
      message: 'Error',
    })
    return null
  }
}

export const listTeamAction = async params => {
  try {
    let { data } = await axios.get(
      listTeamUrl(), { params: { ...params }, ...getToken() })
    if (data.error) {
      notification.error({
        message: data.message
      })
    }
    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}

export const updateOrderAction = async body => {
  try {
    console.log(body)

    let {data} = await axios.put(
      updateOrderUrl(),
      {...body},
      getToken(),
    )
  
    return data
  } catch (err) {
    notification.error({
      message: 'Error',
    })
    return null
  }
}

export const deleteMemberAction = async body => {
  try {

    let {data} = await axios.delete(
      deleteMemberUrl(body.id),
      getToken(),
    )
    return data
  } catch (err) {
    notification.error({
      message: 'Error',
    })
    return null
  }
}





