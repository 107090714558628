import React, { useEffect, useRef, useState } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils4'
import { Card, Button, Table, Tooltip, notification, Popconfirm, Image, message } from 'antd'
import { listDeveloperAction, deleteDeveloperAction } from '../actions/index'
import moment from 'moment'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { getUrlPushWrapper } from '../../../routes'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import AddDeveloper from './add'
import { DiffOutlined } from '@ant-design/icons'



const AllDeveloper = () => {
  const dispatch = useDispatch()
  const [total, setTotal] = useState(0)
  const tableRef = useRef()
  const [open, setOpen] = useState(false)
  const [devId, setDevId] = useState(null)
  const [key, setKey] = useState(null)
const [cloneId, setCloneId]= useState(null)
  const apiRequest = params => {
    return new Promise(async resolve => {
      
      if (!params.sortField || !params.sortOrder) {
        params.sortField = 'name'
        params.sortOrder = 'ascend'
      }

      let data = await listDeveloperAction({
        ...params,
        regExFilters: ['name', 'designation']
      })
      if (data && !data.error) {
        setTotal(data.total)
      }

      return resolve(data)
    })

  }

  const columns = [
    {
      title: 'Logo',
      key: 'logo',
      width: 100,
      render: (val, record) => {
        return <Image src={record.logoUrl} height={50} width={50}/>
      }
    },
    {
      title: 'Developer Title',
      key: 'developerTitle',
      dataIndex: 'developerTitle',
      searchTextName: 'name',
      width: 200
    },
    {
      title: 'Developer Year',
      key: 'developerYear',
      searchTextName: 'developerYear',
      width: 200
    },
    {
      title: 'Developer Link',
      key: 'developerLink',
      width: 150,
      searchTextName: 'developerLink'
    },
    {
      width: 100,
      fixed: 'right',
      key: 'actions',
      title: 'Actions',
      render: (text, record) => {
        return (
          <React.Fragment>
            <Tooltip title="Edit Developer">
              <Button
                shape="circle"
                style={{ marginRight: 6 }}
                size="small"
                onClick={() => {
                  // dispatch(getUrlPushWrapper('team.edit', {id: record._id}))
                  setDevId(record._id)
                  setOpen(true)
                  setKey(new Date())
                }}
                icon={<EditOutlined/>}
              />
            </Tooltip>
            <Tooltip title="Clone Developer">
              <Button
                shape="circle"
                style={{ marginRight: 6 }}
                size="small"
                onClick={() => {
                  setCloneId(record._id)
                  setOpen(true)
                  setKey(new Date())
                }}
                icon={<DiffOutlined/>}
              />
            </Tooltip>
            <Popconfirm 
            title="Are You Sure To Delete"
            onConfirm={async () => {
              
                let resp = await  deleteDeveloperAction({id: record._id})
                if (!resp.error) {
                  tableRef.current.reload()
                  message.success("Developer Deleted Successfully")
                } else {
                  message.error("Error Occur On Deleted Developer")
                }

            }}>

            <Tooltip title="Delete Developer">
              <Button
                shape="circle"
                style={{ marginRight: 6 }}
                size="small"
                
                icon={<DeleteOutlined/>}
                />
            </Tooltip>
            </Popconfirm>

          </React.Fragment>
        )
      }
    }
  ]


  return (
    <PageHeaderWrapper title={`All Developers (${total})`}>
      <Card
        bordered={true}
        extra={
          <div>
            <Button
              type="primary"
              icon={<PlusOutlined/>}
              onClick={() => {
                setOpen(true)
                setDevId(null)
                setKey(new Date())
              }}>
              Add New Developer
            </Button>
          </div>
        }>
        <TableComp columns={columns} apiRequest={apiRequest} ref={tableRef} extraProps={{ scroll: { x: 1000 } }}/>
      </Card>

      {open &&
      <AddDeveloper
        key={key}
        onSubmit={() => {
          tableRef.current.reload()
          setDevId(null)
          setCloneId(null)
        }}
        id={devId}
        cloneId={cloneId}
      />


      }


    </PageHeaderWrapper>
  )
}

export default AllDeveloper
