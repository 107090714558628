import Dashboard from './containers/dashboard'
import { push } from 'connected-react-router'
import _ from 'lodash'
import {
  DashboardOutlined,
  UserOutlined,
  InsertRowBelowOutlined, ContactsOutlined
} from '@ant-design/icons'
import AddUsers from './containers/users/views/add'
import AllUsers from './containers/users/views/list'

import { Path } from 'path-parser'
import AddProperty from './containers/properties/views/add'
import AllTeam from './containers/team/views/all'
import AllDevelopers from './containers/developer/views/all'
import AllArea from './containers/area/views/all'
import AllContacts from './containers/contacts/views/all'
import AllProperties from './containers/properties/views/all'

let menu = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    title: 'Dashboard',
    icon: <DashboardOutlined/>,
    key: 'dashboard',
    homepage: true,
    component: Dashboard,
    authority: ['admin', 'dealer']
  },

  {
    path: '/users',
    icon: <UserOutlined/>,
    name: 'Users',
    key: 'users',
    authority: ['admin'],
    children: [
      {
        path: '/users/add',
        name: 'Add User',
        title: 'Add User',
        key: 'add',
        component: AddUsers,
        dontShowOnMenu: true

      },
      {
        path: '/users/all',
        name: 'All User',
        title: 'All User',
        component: AllUsers,
        isLazy: true
      },
      {
        path: '/users/edit/:id',
        name: 'Edit User',
        title: 'Edit User',
        dontShowOnMenu: true,
        key: 'edit',
        component: AddUsers,
        isLazy: true
      }
    ]
  },
  {
    path: '/team',
    icon: <InsertRowBelowOutlined/>,
    name: 'Team',
    key: 'team',
    authority: ['admin'],
    children: [
      {
        path: '/team/list',
        name: 'All Teams',
        title: 'All Teams',
        key: 'teamlist',
        component: AllTeam
      }
    ]
  },
  {
    path: '/developer',
    icon: <InsertRowBelowOutlined/>,
    name: 'Developer',
    key: 'developer',
    authority: ['admin'],
    children: [
      {
        path: '/developer/list',
        name: 'All Developer',
        title: 'All Developer',
        key: 'developerList',
        component: AllDevelopers
      }
    ]
  },
  {
    path: '/area',
    icon: <InsertRowBelowOutlined/>,
    name: 'Area',
    key: 'area',
    authority: ['admin'],
    dontShowOnMenu: true,
    children: [
      {
        path: '/area/list',
        name: 'All Area',
        title: 'All Area',
        key: 'areaList',
        component: AllArea
      }
    ]
  },
  {
    path: '/contacts',
    icon: <ContactsOutlined/>,
    name: 'Contacts',
    key: 'contacts',
    authority: ['admin'],
    children: [
      {
        path: '/contacts/list',
        name: 'All Contacts',
        title: 'All Contacts',
        key: 'contactList',
        component: AllContacts
      }
    ]
  },
  {
    path: '/properties',
    icon: <ContactsOutlined/>,
    name: 'Properties',
    key: 'properties',
    authority: ['admin'],
    children: [
      {
        path: '/properties/list',
        name: 'All Properties',
        title: 'All Properties',
        key: 'propertiesList',
        component: AllProperties
      },
      {
        path: '/properties/edit',
        name: 'Edit Property',
        title: 'Edit Property',
        dontShowOnMenu: true,
        key: 'edit',
        component: AddProperty,
        isLazy: true
      },
      {
        path: '/properties/add',
        name: 'Add Property',
        title: 'Add Property',
        dontShowOnMenu: true,
        key: 'add',
        component: AddProperty,
        isLazy: true
      }
    ]
  }
]

export const getUrlPushWrapper = (keyString, query) => {
  return push(getUrlPath(keyString, query))
}

export const getUrlPath = (keyString, params) => {
  if (!params) params = {}

  let keyArr = keyString.split('.')

  let val = _.find(menu, p => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  let queryString = Object.keys(params)
    .map(key => key + '=' + params[key])
    .join('&')

  return `${val.path}?${queryString}`
}

export const getUrlParams = (keyString, route) => {
  let obj = getUrlObject(keyString)

  if (obj) {
    console.log('objjjjj is', obj, route)
    const path = new Path(obj.path)

    return path.test(route)
  }

  return { error: true }
}

export const getUrlObject = keyString => {
  let keyArr = keyString.split('.')

  let val = _.find(menu, p => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  return val
}


export default menu
