import React, { useEffect, useState } from 'react'
import { Form, Button, Card, notification, Select, Drawer, Space } from 'antd'
import GetAllFormFields from '../../../components/_utils/formUtils'
import { addUserAction, editUserAction, userListAction } from '../actions'
import { API_URL, userType } from '../../../request'
import { useDispatch } from 'react-redux'

const AddUsers = props => {
  const [loader, setLoader] = useState(false)
  const [showStaff, setStaffShow] = useState(false)
  const dispatch = useDispatch()
  const {onSubmit, id } = props
  const [open , setOpen] = useState(true)

  const [form] = Form.useForm()
  let { resetFields, setFieldsValue, getFieldValue } = form

  const onFinish = async values => {

    let x = null
    if (values.password !== values.confirmPassword) {
      notification.warning({
        message: 'Passwords do not match'
      })
      return
    }
    delete values.confirmPassword

    setLoader(true)
    if (id) {
      values._id = id
      x = await editUserAction(values)
      setLoader(false)

    } else {
      x = await addUserAction(values)
      setLoader(false)

    }

    if (!x.error) {
      notification.success({
        message: x.message
      })
      if (!id) {

        setStaffShow(false)
        setOpen(false)
        onSubmit()
        
      } else {
        
        setOpen(false)
        onSubmit()
        // dispatch(goBack())
      }

    } else {
      notification.error({
        message: x.message ? x.message : 'Error Saving'
      })
    }
  }

  const onFinishFailed = errorInfo => {

    return notification.error({
      message: 'Please Fill All Required Fields'
    })
  }

  useEffect(async () => {
    if (id) {
      let { data } = await userListAction({ _id: id })
      if (data && data.length) {
        let agent = data[0]
        setFieldsValue({ ...agent })
      }

    }
  }, [id])

  const subInputTypes1 = {
    fields: [

      {
        label: 'Name',
        key: 'name',
        required: true
      },
      {
        label: 'Email',
        key: 'email',
        required: true,
        type: 'email'
      },
      {
        label: 'Password',
        key: 'password',
        required: true,
        type: 'password',
        hidden: id
      },
      {
        label: 'Confirm Password',
        key: 'confirmPassword',
        required: true,
        type: 'password',
        hidden: id
      },
      {
        label: 'User Type',
        key: 'userType',
        type: 'select',
        options: userType,
        showSearch: true,
        keyAccessor: (x) => x.id,
        valueAccessor: (x) => `${x.value}`,
        required: true
      }


    ]
  }

  return (
    <Drawer title={id ? 'Update User' : 'Add Users'}
            placement="right"
            onClose={() => setOpen(false)}
            visible={open}
            destroyOnClose={true}
            width={500}
    >
      <Card bordered={false}>
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}>

          <GetAllFormFields inputSchema={subInputTypes1}/>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit" loading={loader}>
              {id ? 'Update' : 'Add'}
            </Button>
          </Form.Item>
        </Form>
      </Card>

    </Drawer>
  )
}

export default AddUsers
