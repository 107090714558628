import React, {useEffect, useRef, useState} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils4'
import {Card, Button, Tooltip, Drawer} from 'antd'
import {userListAction} from '../actions/index'
import _ from 'lodash'
import {useDispatch} from 'react-redux'
import {getUrlPushWrapper} from '../../../routes'

import {userType} from '../../../request'
import {SettingOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons'
import AddUser from "./add"


const AllUsers = () => {
  const dispatch = useDispatch()
  const [total, setTotal] = useState(0)
  const tableRef = useRef()
  const [open, setOpen] = useState(false)
  const [id , setId] = useState(null)
  const [key, setKey] = useState(null);

  const apiRequest = params => {
    return new Promise(async resolve => {
      if (!params.sortField || !params.sortOrder) {
        params.sortField = 'name'
        params.sortOrder = 'ascend'
      }

      let data = await userListAction({
        ...params,
        regExFilters: ['name', 'designation']
      })
      if (data && !data.error) {
        setTotal(data.total)
      }
      return resolve(data)
    })
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      width: 150,
      key: 'name',
      searchTextName: 'name',
      fixed: 'left'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 150,
      sorter: true,
      key: 'email',
      searchTextName: 'email'

    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      width: 150,
      key: 'mobile',
      searchTextName: 'mobile'

    },
    {
      title: 'User Type',
      dataIndex: 'userType',
      width: 150,
      sorter: true,
      key: 'userType',
      render: (val, record) => {
        return _.startCase(val && val.replace(/([A-Z])/g, ' $1'))
      },
      filters: _.map(userType, (item) => {
        return {value: item.id, text: item.value}
      })
    },
    {
      key: 'actions',
      title: 'Actions',
      width: 100,
      fixed: 'right',
      render: (text, record) => {
        return (
          <React.Fragment>
            <Tooltip title="Edit User">
              <Button
                shape="circle"
                style={{marginRight: 6}}
                size="small"
                onClick={() => {

                  setOpen(true)
                  setId(record._id)
                  setKey(new Date())
                  
                  // dispatch(
                  //   getUrlPushWrapper('users.editUser', {id: record._id})
                  // )
                }}
                icon={<EditOutlined/>}
              />
            </Tooltip>


            {/* <Tooltip title="Change Password">
              <Button
                shape="circle"
                style={{marginRight: 6}}
                size="small"
                onClick={() => {
                  dispatch(
                    getUrlPushWrapper('users.changePass', {id: record._id})
                  )
                }}
                icon={<SettingOutlined/>}
              />
            </Tooltip> */}
          </React.Fragment>
        )
      }
    }
  ]

  return (
    <PageHeaderWrapper title={`All Users(${total})`}>
      <Card
        bordered={true}
        extra={
          <div>
            <Button
              type="primary"
              icon={<PlusOutlined/>}
              onClick={() => {
                setKey(new Date())
                setId(null)
                setOpen(true);
              }}>
              Add New User
            </Button>
          </div>
        }>


        <TableComp columns={columns} apiRequest={apiRequest} ref={tableRef}/>
      </Card>

      {open && 
        <AddUser
          id={id}
          key={key}
          onSubmit={() => {

            setId(null)
            tableRef.current.reload();
            
          }}
        />
      }
      

    </PageHeaderWrapper>
  )
}

export default AllUsers
