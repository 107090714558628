import { addAreaUrl, listAreaUrl,updateAreaUrl } from '../api/index'
import axios from 'axios'
import { notification } from 'antd'
import { getToken } from '../../../request'


export const addAreaAction = async params => {
  try {
    let { data } = await axios.post(
      addAreaUrl(),
      { ...params },
      getToken()
    )
    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}
export const updateAreaAction = async params => {
  try {
    console.log(params)
    let {data} = await axios.put(
      updateAreaUrl(),
      {...params},
      getToken(),
    )
  
    return data
  } catch (err) {
    notification.error({
      message: 'Error',
    })
    return null
  }
}

export const listAreaAction = async params => {
  try {
    let { data } = await axios.get(
      listAreaUrl(), { params: { ...params }, ...getToken() })
    if (data.error) {
      notification.error({
        message: data.message
      })
    }
    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}



