import {API_URL} from '../../../request'

export const addTeamUrl = () => {
    return API_URL + `/team/add`
}
export const listTeamUrl = () => {
    return API_URL + `/team/list`
}
export const updateTeamUrl = () => {
    return API_URL + `/team/update`
}
export const updateOrderUrl = () => {
    return API_URL + `/team/updateMany`
}
export const deleteMemberUrl = (id) => {
    return API_URL + `/team/delete/${id}`
}


