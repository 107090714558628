const initialState = {
  collapsed: false,
  navTheme: 'dark', // theme for nav menu
  layout: 'sidemenu', // nav menu position: sidemenu or topmenu
  contentWidth: 'Fluid', // layout of content: Fluid or Fixed, only works when layout is topmenu
  fixedHeader: false, // sticky header
  autoHideHeader: false, // auto hide header
  fixSiderbar: false, // sticky siderbar,
  mainLayoutClass: '',
}

export default (state = initialState, action) => {
  switch (action.type) {

      case 'TOGGLE_SIDEBAR_COLLAPSED':
          return {
              ...state,
              collapsed: !state.collapsed,
          }
      case 'SIDEBAR_COLLAPSED':
          return {
              ...state,
              collapsed: true,
          }

      case 'SET_MAINLAYOUT_CLASS':
          return {
              ...state,
              mainLayoutClass: action.data,
          }

      default:
          return state
  }
}
