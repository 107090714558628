import React, {useEffect, useRef, useState} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils4'
import {Card, Button, Table, Tooltip, notification, Popconfirm} from 'antd'
import {listAreaAction} from '../actions/index'
import moment from 'moment'
import _ from 'lodash'
import {useDispatch} from 'react-redux'
import {getUrlPushWrapper} from '../../../routes'
import {DeleteOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons'
import AddArea from './add'


const AllArea = () => {
    const dispatch = useDispatch()
    const [total, setTotal] = useState(0)
    const tableRef = useRef()
    const [open ,setOpen] = useState(false);
    const [areaId, setAreaId] = useState(null)

    const apiRequest = params => {
        return new Promise(async resolve => {
            if (!params.sortField || !params.sortOrder) {
                params.sortField = 'name'
                params.sortOrder = 'ascend'
            }

            let data = await listAreaAction({
                ...params,
                regExFilters: ['name', 'slug']
            })
            if (data && !data.error) {
                setTotal(data.total)
            }
            return resolve(data)
        })

    }

    const columns = [
 
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            searchTextName: 'name',
            width: 200,
        },
        {
            title: 'Slug',
            key: 'slug',
            width: 200,
        
        },
        {
            key: 'actions',
            title: 'Actions',
            render: (text, record) => {
                return (
                    <React.Fragment>
                        <Tooltip title="Edit Area">
                            <Button
                                shape="circle"
                                style={{marginRight: 6}}
                                size="small"
                                onClick={() => {
                                setOpen(true)
                                setAreaId(record._id)

                                    // dispatch(getUrlPushWrapper('team.edit', {id: record._id}))
                                }}
                                icon={<EditOutlined/>}
                            />
                        </Tooltip>
                    </React.Fragment>
                )
            }
        }
    ]

    return (
        <PageHeaderWrapper title={`All Areas (${total})`}>
            <Card
                bordered={true}
                extra={
                    <div>
                        <Button
                            type="primary"
                            icon={<PlusOutlined/>}
                            onClick={() => {
                                setOpen(true)
                                // dispatch(getUrlPushWrapper('team.add'))
                            }}>
                            Add New Area
                        </Button>
                    </div>
                }>
                <TableComp columns={columns} apiRequest={apiRequest} ref={tableRef}/>
            </Card>

            <AddArea 
            id={areaId}
            open={open}
            onClose={() => setOpen(false)}
            onSubmit={() => {tableRef.current.reload()}}
            />

        </PageHeaderWrapper>
    )
}

export default AllArea
