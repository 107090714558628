import { addPropertyUrl, listPropertyUrl, updatePropertyUrl, deleteFileUrl , deleteProppertyUrl, updateOrderProppertyUrl} from '../api/index'
import axios from 'axios'
import { notification } from 'antd'
import { getToken } from '../../../request'


export const addPropertyAction = async params => {
  try {
    let { data } = await axios.post(
      addPropertyUrl(),
      { ...params },
      getToken()
    )
    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}
export const updatePropertyAction = async params => {
  try {
    console.log(params)
    let { data } = await axios.put(
      updatePropertyUrl(),
      { ...params },
      getToken()
    )

    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}

export const listPropertyAction = async params => {
  try {
    let { data } = await axios.get(
      listPropertyUrl(), { params: { ...params }, ...getToken() })
    if (data.error) {
      notification.error({
        message: data.message
      })
    }
    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}

export const deleteFileAction = async params => {
  try {
    let { data } = await axios.post(
      deleteFileUrl(),
      { ...params },
      getToken()
    )
    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}

export const deletePropertyAction = async params => {
  try {
    let { data } = await axios.delete(
      deleteProppertyUrl(params.id), { ...getToken() })
    if (data.error) {
      notification.error({
        message: data.message
      })
    }
    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}

export const updatePropertyOrderAction = async params => {
  try {
    console.log(params)
    let { data } = await axios.put(
      updateOrderProppertyUrl(),
      { ...params },
      getToken()
    )

    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}




