import React, { useEffect, useRef, useState } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils4'
import { Card } from 'antd'
import { listContactAction } from '../actions/index'
import { useDispatch } from 'react-redux'
import moment from 'moment'

const AllContact = () => {
  const dispatch = useDispatch()
  const [total, setTotal] = useState(0)
  const tableRef = useRef()

  const apiRequest = params => {
    return new Promise(async resolve => {
      if (!params.sortField || !params.sortOrder) {
        params.sortField = '_id'
        params.sortOrder = 'descend'
      }

      let data = await listContactAction({
        ...params,
        regExFilters: ['name', 'lastName', 'email', 'phoneNumber', 'message', 'formType']
      })
      if (data && !data.error) {
        setTotal(data.total)
      }
      return resolve(data)
    })

  }

  const columns = [

    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      searchTextName: 'name',
      width: 200,
      fixed: 'left'
    },
    {
      title: 'Last Name',
      key: 'lastName',
      dataIndex: 'lastName',
      searchTextName: 'lastName',
      width: 200
    },
    {
      title: 'Phone Number',
      key: 'phoneNumber',
      width: 200,
      searchTextName: 'phoneNumber'
    },
    {
      title: 'WhatsApp Number',
      key: 'whatsAppNumber',
      width: 200,
      searchTextName: 'whatsAppNumber'
    },
    {
      title: 'Email',
      key: 'email',
      width: 200,
      searchTextName: 'email'
    },
    {
      title: 'Message',
      key: 'message',
      width: 200,
      searchTextName: 'message'
    },
    {
      title: 'Form Type',
      key: 'formType',
      width: 200,
      searchTextName: 'formType'
    },
    {
      title: 'Created At',
      key: 'createdAt',
      width: 200,
      sorter: true,
      render: (val) => <div>{moment(val).format('DD-MMM-YYYY')}</div>,
      searchDateName:'createdAt'
    }
  ]

  return (
    <PageHeaderWrapper title={`All Contacts (${total})`}>
      <Card bordered={true}>
        <TableComp columns={columns} apiRequest={apiRequest} ref={tableRef} extraProps={{ scroll: { x: 1000 } }}
        />
      </Card>

    </PageHeaderWrapper>
  )
}

export default AllContact
