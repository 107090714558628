import { listContactUrl } from '../api/index'
import axios from 'axios'
import { notification } from 'antd'
import { getToken } from '../../../request'

export const listContactAction = async params => {
  try {
    let { data } = await axios.get(
      listContactUrl(), { params: { ...params }, ...getToken() })
    if (data.error) {
      notification.error({
        message: data.message
      })
    }
    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}



