import React, { useEffect, useRef, useState } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils4'
import { Card, Button, Table, Tooltip, notification, Popconfirm, message, Switch , 
  // Table as TableComp
 } from 'antd'
import { listPropertyAction , updatePropertyOrderAction } from '../actions/index'
import moment from 'moment'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { DeleteOutlined, EditOutlined, PlusOutlined , DiffOutlined , DragOutlined, SwapOutlined} from '@ant-design/icons'
import AddProperty from './add'
import { projectType } from '../../../request'

import { updatePropertyAction , deletePropertyAction} from '../actions'
import "./styles.less"



import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
const DragHandle = SortableHandle(() => (
  <DragOutlined
    style={{
      cursor: 'grab',
      color: '#999',
    }}
  />
));


const AllProperty = () => {
  const dispatch = useDispatch()
  const [total, setTotal] = useState(0)
  const tableRef = useRef()
  const [open, setOpen] = useState(false)
  const [propertyId, setPropertyId] = useState(null)
  const [key, setKey] = useState(null)
  const [dataSource, setDataSource] = useState([])
  const [loading, setLoading] = useState(false)
  const [isOrderUpdate, setIsOrderUpdate] = useState(false)

  const apiRequest = (params = {}) => {
    return new Promise(async resolve => {
      setLoading(true)
      if (!params.sortField || !params.sortOrder) {
        params.sortField = 'order'
        params.sortOrder = 'ascend'
      }
      params.results = 1000;

      let data = await listPropertyAction({
        ...params,
        regExFilters: ['projectName', 'location', 'paymentPlan']
      })
      if (data && !data.error) {
      
        setDataSource(data.data.map((x, i) => {x.index = x.order; return x}))
        setTotal(data.total)
      }
      setLoading(false)
      return resolve(data)
    })
  }
  useEffect(() => {
    apiRequest()
  }, [])

  const handleToggle =  async (id ,field, val ) => {

    if (id && field) {

      let values =  {};
      values._id = id
      values[field] = val;

      // console.log(values , "thsi iasfd ;alksdj flaksjdf lk")
      const  x = await updatePropertyAction(values)


      if (x.success) {

        tableRef.current.reload()
        message.success("Update Successfuly")
      } else {
        message.error("Error Occur On Update")

      }

    } 
  
  }

  const columns = [
    {
      // title: 'Sort',
      // dataIndex: 'sort',
      align: "center",
      fixed: "left",
      width: 60,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },

    {
      title: 'Project Name',
      key: 'projectName',
      dataIndex: 'projectName',
      width: 180,
      fixed: 'left',
      // searchTextName: 'projectName',
      // sorter: true,
      // onFilter: (value, record) => {
      //   if(record.projectName.toLowerCase().includes(value.toLowerCase())) {
      //     return true
      //   } else {
      //     return false
      //   }
      // },

    },
    {
      title: 'Location',
      key: 'location',
      width: 120,
      // searchTextName: 'location',
      // onFilter: (val, record) => record.location?.toLowerCase()?.includes(val?.toLowerCase())


    },
    // {
    //   title: 'Area',
    //   key: 'area',
    //   width: 200

    // },
    {
      title: 'Developer',
      key: 'developerId',
      dataIndex:"developerId",
      width: 120,
      render: (val) => {
        return <div> {val?.developerTitle}</div>
      },
      // searchTextName: true,
      // onFilter: (val, record) => record?.developerId?.developerTitle?.toLowerCase()?.includes(val?.toLowerCase())


    },
    {
      title: 'Handover',
      key: 'handoverQuarter',
      width: 120

    },
    {
      title: 'Payment Plan',
      key: 'paymentPlanDetails',
      width: 120

    },
    {
      title: 'Show On Home Page',
      key: 'onHomePage',
      width: 120,
      render: (val, record ) => {
        return <Switch defaultChecked={val} onChange={(v)=>handleToggle(record._id, "onHomePage", v)}/>
      },
      // filters: [
      //   {
      //     text: 'Yes',
      //     value: true,
      //   },
      //   {
      //     text: 'No',
      //     value: false,
      //   },
      // ],
      // onFilter: (value, record) => {
      //   if(record.onHomePage == value) {
      //     return true
      //   } else {
      //     return false
      //   }
      
      // },

    },
    
    // {
    //   title: 'Completed In 2023',
    //   key: 'completedIn23',
    //   width: 200,
    //   render: (val, record ) => {
    //     return <Switch defaultChecked={val} onChange={(v)=>handleToggle(record._id, "completedIn23", v)}/>
    //   }

    // },
    // {
    //   title: 'For Investment',
    //   key: 'forInvestment',
    //   width: 200,
    //   render: (val, record ) => {
    //     return <Switch defaultChecked={val} onChange={(v)=>handleToggle(record._id, "forInvestment", v)}/>
    //   }

    // },
    {
      title: 'New Launch',
      key: 'newLaunch',
      width: 120,
      render: (val, record ) => {
        return <Switch defaultChecked={val} onChange={(v)=>handleToggle(record._id, "newLaunch", v)}/>
      },
      // filters: [
      //   {
      //     text: 'Yes',
      //     value: true,
      //   },
      //   {
      //     text: 'No',
      //     value: false,
      //   },
      // ],
      // onFilter: (value, record) => {
      //   if(record.newLaunch == value) {
      //     return true
      //   } else {
      //     return false
      //   }
      
      // },

    },
    
    {
      title: 'Property Type',
      key: 'projectType',
      width: 120,
      render: (val, row) => {
        return row && row.projectType.join(', ')
      },

      // filters: _.map(projectType, (x) => {
      //   return { text: x, value: x }
      // }),
      // onFilter: (value, record) => {
      //   if(record.projectType.find(x => x.toLowerCase() == value.toLowerCase())) {
      //     return true
      //   } else {
      //     return false
      //   }
      
      // },

    },
    {
      title: 'Starting Price',
      key: 'startFrom',
      width: 120

    },
    {
      title: 'Available Units',
      key: 'layout',
      width: 120

    },
    // {
    //   title: 'Created At',
    //   key: 'createdAt',
    //   width: 200,
    //   sorter: true,
    //   render: (val) => <div>{moment(val).format("DD-MMM-YYYY")}</div>

    // },
    {
      key: 'actions',
      title: 'Actions',
      fixed: "right",
      width: 120,
      render: (text, record) => {
        return (
          <React.Fragment>
            <Tooltip title="Edit Property">

              <a
                href={`/properties/edit?id=${record && record._id}`}
                target={'_blank'}>
                <Button
                  shape="circle"
                  style={{ marginRight: 6 }}
                  size="small"
                  onClick={() => {
                    setOpen(true)
                    setPropertyId(record._id)
                    setKey(new Date())
                  }}
                  icon={<EditOutlined/>}
                />
              </a>
            </Tooltip>
            <Tooltip title={"Clone Property"}>
            <a
                href={`/properties/edit?cloneId=${record && record._id}`}
                target={'_blank'}>
                <Button
                  shape="circle"
                  style={{ marginRight: 6 }}
                  size="small"
                  icon={<DiffOutlined />}
                />
              </a>
            </Tooltip>
            <Popconfirm
            title="Are You Sure To delete This Property"
            onConfirm={async () => {
              try {
             let resp = await  deletePropertyAction({id: record._id})
             if (!resp.error) {
              message.success("Propety Deleted Successfuly")
              tableRef.current?.reload()
             } else {
              message.error("Error Occur On Delete Property")
             }

              } catch(e) {
                message.error("Error Occur On Delete Property")
              }


            }}
            >
            <Tooltip title={"Delete Property"} >
                <Button
                  shape="circle"
                  style={{ marginRight: 6 }}
                  size="small"
                  onClick={() => {
                   
                  }}
                  icon={<DeleteOutlined/>}
                  />
            </Tooltip> 
            </Popconfirm>

          </React.Fragment>
        )
      }
    }
  ]
  const SortableItem = SortableElement((props) => <tr {...props} />);
  const SortableBody = SortableContainer((props) => <tbody {...props} />);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(dataSource.slice(), oldIndex, newIndex).filter(
        (el) => !!el,
      );
      setDataSource(newData.map((x , i)=> {x.index = (i + 1); return x}))

      // update new index to db 
      setIsOrderUpdate(true)
     
    }
  };
  const DraggableContainer = (props) => {
    return (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );}
  const DraggableBodyRow = ({ className, style, isDragging, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index

    const customStyle = isDragging ? {backgroundColor: "blue"} : {
      ...style,
    }
    const index = dataSource.findIndex((x) => x.index === restProps['data-row-key']);
    return <SortableItem index={index} style={customStyle} {...restProps} />;

  };

 const hanldePropertyOrderUpdate =() => {

  updatePropertyOrderAction(dataSource.map((x, i) => ({id: x._id, order: i +1}) ))
  .then((res) => {
    setIsOrderUpdate(false)
    apiRequest().then().catch()
    if (res.success) {
      message.success("Property Order Updated")
    } else {
      message.error("Error Occur On Updated Property Order")
    }
  })

 }

  return (
    <PageHeaderWrapper title={`Property List (${total})`}>
      <Card
        bordered={true}
        extra={
          <div style={{display: "flex", gap: 20}}>
            <Button
              // type="primary"
              danger={isOrderUpdate ? true : false}
              disabled={isOrderUpdate ? false : true}
              type={isOrderUpdate ? "dashed" : "default"}
              bordered
              icon={<SwapOutlined />}
              onClick={() => {
                hanldePropertyOrderUpdate()
              }}>
              Update Property Order
            </Button>
            <a

              href={`/properties/add`}
              target={'_blank'}>
              <Button
                type="primary"
                icon={<PlusOutlined/>}
                onClick={() => {
                  setOpen(true)
                  setKey(new Date())
                  setPropertyId('')
                }}>
                Add New Property
              </Button>
            </a>

          </div>
        }>
        <TableComp
         columns={columns}
         //  apiRequest={apiRequest}
         loading={loading}
         rowKey="index"
         dataSource={dataSource}
         ref={tableRef}
         extraProps={{ 
          scroll: { x: 700 },
          components: {
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
         }
        }}
        //  pagination={{pageSize: 1000, defaultPageSize: 1000, pageSizeOptions: [1000]}}
         pagination={{pageSize: 1000, defaultPageSize: 1000, pageSizeOptions: [1000], hideOnSinglePage: true}}
        
        />

      
      </Card>

      {/*<AddProperty*/}
      {/*  id={propertyId}*/}
      {/*  open={open}*/}
      {/*  onClose={() => setOpen(false)}*/}
      {/*  onSubmit={() => {*/}
      {/*    tableRef.current.reload()*/}
      {/*    setPropertyId('')*/}
      {/*  }}*/}
      {/*  key={key}*/}
      {/*/>*/}

    </PageHeaderWrapper>
  )
}

export default AllProperty
