import React, {useEffect, useState} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {Form, Button, Card, notification, Select, Drawer, Spin} from 'antd'
import GetAllFormFields from '../../../components/_utils/formUtils'
import {addTeamAction, updateTeamAction, listTeamAction} from '../actions'
import {useDispatch} from 'react-redux'
import {goBack} from 'connected-react-router'
import {handlePreview} from "../../../request";

const AddTeam = props => {
    const [loader, setLoader] = useState(false)
    const [showStaff, setStaffShow] = useState(false)
    const dispatch = useDispatch()
    const [open, setOpen] = useState(true)
    const [form] = Form.useForm()
    let {resetFields, setFieldsValue, getFieldValue} = form
    const {onSubmit, id} = props;

    const onFinish = async values => {

        let x = null
        setLoader(true)
        if (id) {
            values._id = id
            x = await updateTeamAction(values)
            setLoader(false)

        } else {
            x = await addTeamAction(values)
            setLoader(false)

        }

        if (!x.error) {
            notification.success({
                message: x.message
            })
            if (!id) {
                setStaffShow(false)
                // resetFields()
                onSubmit()
                setOpen(false)
                
            } else {
                // dispatch(goBack())
                onSubmit()
                setOpen(false)

            }

        } else {
            notification.error({
                message: x.message ? x.message : 'Error Saving'
            })
        }
    }

    const onFinishFailed = errorInfo => {
        return notification.error({
            message: 'Please Fill All Required Fields'
        })
    }

    useEffect(async () => {
        if (id) {
            setLoader(true)
            let {data} = await listTeamAction({_id: id})
            if (data && data.length) {
                let agent = data[0]
                setFieldsValue({...agent})
            }
            setLoader(false)
        }
    }, [id])

    const subInputTypes1 = {
        fields: [

            {
                label: 'Name',
                key: 'name',
                required: true
            },
            {
                label: 'Photo',
                key: 'photo',
                required: true,
                type: 'file',
                customProps: {
                    onPreview: (file) => {
                        handlePreview(file)
                    }
                }

            },
            {
                label: 'Thumbnail',
                key: 'thumbnail',
                //required: true,
                type: 'file',
                customProps: {
                    onPreview: (file) => {
                        handlePreview(file)
                    }
                }

            },

            {
                label: 'Mobile',
                key: 'phoneNumber',
                required: true,
            },
            {
                label: 'Designation',
                key: 'designation',
                required: true,
            },
            {
                label: 'Property Finder Id',
                key: 'propertyFinderId',
                //required: true,
            },
            {
                label: 'Bayut Id',
                key: 'bayutId',
                //required: true,
            },
            {
                label: 'LinkedIn',
                key: 'linkedIn',
                //required: true,
            }, {
                label: 'WhatsApp',
                key: 'whatsapp',
                //required: true,
            }, {
                label: 'Email',
                key: 'email',
                //required: true,
            },


        ]
    }

    return (
        <Drawer 
        title={id ? 'Update Team' : 'Add Team'}
        visible={open}
        onClose={() => setOpen(false)}
        width={500}
        destroyOnClose={true}
        >
            <Card bordered={false}>
                <Spin spinning={loader}>

                <div>
                    
                    <Form
                        form={form}
                        name="basic"
                        labelCol={{span: 8}}
                        wrapperCol={{span: 16}}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}>

                        <GetAllFormFields inputSchema={subInputTypes1}/>
                        <Form.Item wrapperCol={{offset: 8, span: 16}}>
                            <Button type="primary" htmlType="submit" loading={loader}>
                                {id ? 'Update' : 'Add'}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>

                </Spin>

            </Card>
        </Drawer>
    )
}

export default AddTeam
