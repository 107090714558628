import {API_URL} from '../../../request'

export const addAreaUrl = () => {
    return API_URL + `/area/add`
}
export const listAreaUrl = () => {
    return API_URL + `/area/list`
}
export const updateAreaUrl = () => {
    return API_URL + `/area/update`
}
