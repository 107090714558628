import {API_URL} from '../../../request'

export const addDeveloperUrl = () => {
    return API_URL + `/developer/add`
}
export const listDeveloperUrl = () => {
    return API_URL + `/developer/list`
}
export const updateDeveloperUrl = () => {
    return API_URL + `/developer/update`
}
export const deleteDeveloperUrl = (id) => {
    return API_URL + `/developer/${id}`
}