import axios from 'axios'
import React from 'react'


// export let API_URL = `http://localhost:8040`
export let API_URL = `https://ckapi.realedge.tech`

export const pageTitle = 'CK Website CRM'

export const timeStampFormat = 'DD/MM/YYYY'

if (process.env.NODE_ENV == 'production') {
  API_URL = 'https://ckapi.realedge.tech'
}

let authAxios = axios.create({
  baseURL: API_URL
})
export const errorObj = { error: true, type: 'error', success: false }
export const googleApiKey = 'AIzaSyBEKNUu8AmsB636O5GG-lj0hgHuEVjDPbQ'
export const successObj = { error: false, type: 'success', success: true }
export const userType = [
  { id: 'user', value: 'User' },
  { id: 'admin', value: 'Admin' }
]

export function downloadData(url, filename) {
  fetch(url).then(function(t) {
    return t.blob().then((b) => {
        let a = document.createElement('a')
        a.href = URL.createObjectURL(b)
        a.setAttribute('download', filename)
        a.click()
      }
    )
  })
}

export const handlePreview = async (file) => {
  let url
  if (file && file.response && file.response[0].path) {
    file.response.path = file.response[0].path.replace(/^public/, '')
    url = `${API_URL}/${file.response.path}`
  } else {
    url = `${API_URL}/${file.url}`
  }

  window.open(url, '_blank')
}
export const paymentPlan = [{
  description: 'after 2 month, oct 2024',
  percentage: '10%',
  id: 1
}, { id: 2, description: 'after 2 month, oct 2024', percentage: '20%' }]

export const projectType = ['Villas', 'Apartments', 'Penthouses', 'Townhouses', 'Duplex']
export const generateArrayOfYears = () => {

  let max = 2030
  let min = max - 50
  let years = []

  for (let i = max; i >= min; i--) {
    years.push(i.toString())
  }
  return _.sortBy(years, (item) => item)

}

export const getToken = () => {
  return {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }
}
axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {


    if (error && error.response && error.response.status == '401') {
      try {
        localStorage.clear()
        window.location.reload()

      } catch (e) {
      }
    }
    return Promise.reject(error)
  }
)

class Request {
  constructor() {
    // asyncStorage
    //   .getToken()
    //   .then((data) => {
    //     localStorage.getItem('token') = data
    //   })
  }

  error = err => {
    try {
      if (err.response.status === 401) {
        localStorage.clear()
      }
    } catch (e) {
    }
  }

  deleteHeads(data) {
    return new Promise((next, error) => {
      authAxios
        .delete(
          '/heads',
          { data: { _id: data } },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            }
          }
        )
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          error(err)
          this.error(err)
        })
    })
  }

  login(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/login', data)
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

}


export default new Request()
