import { addDeveloperUrl, listDeveloperUrl,updateDeveloperUrl , deleteDeveloperUrl} from '../api/index'
import axios from 'axios'
import { notification } from 'antd'
import { getToken } from '../../../request'


export const addDeveloperAction = async params => {
  try {
    let { data } = await axios.post(
      addDeveloperUrl(),
      { ...params },
      getToken()
    )
    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}
export const updateDeveloperAction = async params => {
  try {
    console.log(params)
    let {data} = await axios.put(
      updateDeveloperUrl(),
      {...params},
      getToken(),
    )
  
    return data
  } catch (err) {
    notification.error({
      message: 'Error',
    })
    return null
  }
}

export const listDeveloperAction = async params => {
  try {
    let { data } = await axios.get(
      listDeveloperUrl(), { params: { ...params }, ...getToken() })
    if (data.error) {
      notification.error({
        message: data.message
      })
    }
    console.log({data})
    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}

export const deleteDeveloperAction = async params => {
  try {
    let { data } = await axios.delete(
      deleteDeveloperUrl(params.id), { params: { ...params }, ...getToken() })
    if (data.error) {
      notification.error({
        message: data.message
      })
    }
    console.log({data})
    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}

