import { API_URL } from '../../../request'

export const addPropertyUrl = () => {
  return API_URL + `/property/add`
}
export const listPropertyUrl = () => {
  return API_URL + `/property/list`
}
export const updatePropertyUrl = () => {
  return API_URL + `/property/update`
}
export const deleteFileUrl = () => {
  return API_URL + `/delete/file`
}
export const deleteProppertyUrl = (id) => {
  return API_URL + `/property/${id}`
}
export const updateOrderProppertyUrl = (id) => {
  return API_URL + `/property/updateMany`
}
