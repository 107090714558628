import React, { useEffect, useState } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { Form, Button, Card, notification, Select, Drawer, Spin } from 'antd'
import GetAllFormFields from '../../../components/_utils/formUtils'
import { addDeveloperAction, updateDeveloperAction, listDeveloperAction } from '../actions'
import { useDispatch } from 'react-redux'
import { goBack } from 'connected-react-router'
import { API_URL, handlePreview } from '../../../request'


const AddDeveloper = props => {
  const [loader, setLoader] = useState(false)
  const [showStaff, setStaffShow] = useState(false)
  const dispatch = useDispatch()
  const [open, setOpen] = useState(true)

  const [form] = Form.useForm()
  let { resetFields, setFieldsValue, getFieldValue } = form
  const { onSubmit, id , cloneId} = props

  const onFinish = async values => {

    let x = null
    setLoader(true)
    if (id) {
      values._id = id
      x = await updateDeveloperAction(values)
      setLoader(false)

    } else {
      x = await addDeveloperAction(values)
      setLoader(false)

    }

    if (!x.error) {
      notification.success({
        message: x.message
      })
      if (!id) {
        setStaffShow(false)
        // resetFields()
        onSubmit()
        setOpen(false)
      } else {
        // dispatch(goBack())
        onSubmit()
        setOpen(false)
      }

    } else {
      notification.error({
        message: x.message ? x.message : 'Error Saving'
      })
    }
  }

  const onFinishFailed = errorInfo => {
    return notification.error({
      message: 'Please Fill All Required Fields'
    })
  }


  useEffect(async () => {
    if (id) {
      setLoader(true)
      let { data } = await listDeveloperAction({ _id: id })
      if (data && data.length) {
        let agent = data[0]
        setFieldsValue({ ...agent })
      }
      setLoader(false)

    }
  }, [id])

  useEffect(async () => {
    if (cloneId) {
      setLoader(true)
      let { data } = await listDeveloperAction({ _id: cloneId })
      if (data && data.length) {
        let agent = data[0]
        delete agent.logo
        agent.developerTitle = agent.developerTitle + "  Copied"
        setFieldsValue({ ...agent })
      }
      setLoader(false)


    }
  }, [cloneId])


  const subInputTypes1 = {
    fields: [

      {
        label: 'Developer Title',
        key: 'developerTitle',
        required: true
      },
      {
        label: 'Logo',
        key: 'logo',
        required: true,
        type: 'file',
        customProps: {
          onPreview: (file) => {
            handlePreview(file)
          },
          uploadUrl: `${API_URL}/developerUploader`
        }

      },
      {
        label: 'Developer Year',
        key: 'developerYear'

      },

      {
        label: 'Developer Link',
        key: 'developerLink',
        required: true
      },
      {
        label: 'Offer Text',
        key: 'offerText'
      },
      {
        label: 'Description',
        key: 'description',
        type: 'textarea',
        rows: 4
      }


    ]
  }

  return (
    <Drawer
      title={id ? 'Update Developer' : 'Add Developer'}
      visible={open}
      onClose={() => setOpen(false)}
      width={650}
      destroyOnClose={true}
    >
      <Card bordered={false}>
        <Spin spinning={loader}>
          <div>
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}>

              <GetAllFormFields inputSchema={subInputTypes1}/>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit" loading={loader}>
                  {id ? 'Update' : 'Add'}
                </Button>

              </Form.Item>
            </Form>
          </div>
        </Spin>

      </Card>
    </Drawer>
  )
}

export default AddDeveloper
