import React, { useEffect, useRef, useState } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { Card, Button, Tooltip, Spin, Image, message, Popconfirm } from 'antd'
import { listTeamAction, updateOrderAction, deleteMemberAction } from '../actions/index'
import { EditOutlined, PlusOutlined, DragOutlined, DeleteOutlined } from '@ant-design/icons'
import AddTeam from './add'
import DraggableList from 'react-draggable-list'
import style from './styles.less'


const AllTeam = () => {
  const [total, setTotal] = useState(0)
  const [open, setOpen] = useState(false)
  const [teamId, setTeamId] = useState(null)
  const [key, setKey] = useState(null)
  const [orderLoader, setOrderLoader] = useState(false)
  const [loader, setLoader] = useState(false)

  const [arr, setArr] = useState([])

  const apiRequest = params => {
    return new Promise(async resolve => {
      if (!params.sortField || !params.sortOrder) {
        params.sortField = 'order'
        params.sortOrder = 'ascend'
      }
      setLoader(true)
      let data = await listTeamAction({
        ...params,
        regExFilters: ['name', 'designation'],
        results: 1000
      })
      setLoader(false)
      if (data && !data.error) {
        setTotal(data.total)
      }
      return resolve(data)
    })

  }

  const getDataFnx = () => {

    setLoader(true)
    apiRequest({}).then((resp) => {
      if (!resp.error) {
        setArr(resp.data)
      }
      setLoader(false)

    }).catch(e => {
      setLoader(false)
    })

  }

  useEffect(() => {
    getDataFnx()
  }, [])

  const PlanetItem = (props) => {

    const { item, itemSelected, dragHandleProps, onEdit } = props

    const scale = itemSelected * 0.05 + 1
    const shadow = itemSelected * 15 + 1
    const dragged = itemSelected !== 0
    const bgcolor = itemSelected !== 0 ? '#dadada' : 'white'
    return (<>
        <div
          style={{
            transform: `scale(${scale})`,
            backgroundColor: bgcolor,
            boxShadow: `rgba(0, 0, 0, 0.3) 0px ${shadow}px ${2 * shadow}px 0px`
          }}
        >

          <div {...dragHandleProps}
               style={{
                 display: 'inline-block',
                 float: 'left',
                 // background: "blue",
                 width: '50px',
                 height: '20px',
                 padding: '20px'
               }}
          >
            <DragOutlined/>
          </div>
          <div>

            <div className={style.container}>
              <div>
                <Image src={item.imgUrl} className={style.img}></Image>
              </div>
              <div>

                <p className={style.name}>
                  {item.name}
                </p>
                <p className={style.designation}>
                  {item.designation}
                </p>
                <p className={style.phone}>
                  {item.phoneNumber}
                </p>

              </div>

              <div style={{ marginLeft: 'auto' }}>
                <Tooltip title="Edit Team Member">
                  <Button
                    shape="circle"
                    style={{ marginRight: 6 }}
                    size="small"
                    onClick={() => {
                      setTeamId(item._id)
                      setOpen(true)
                      setKey(Date.now().toString())
                    }}
                    icon={<EditOutlined/>}
                  />
                </Tooltip>
                <Popconfirm
                title="Are You Sure To Delete"
                onConfirm={async () => {
                 let resp = await deleteMemberAction({id: item._id});
                 console.log(resp, "this is rep =-=--------------------")
                 if (!resp.error) {
                  message.success("Deleted Successfully")
                  getDataFnx()
                } else {
                   message.error("Error Occur On Delete")

                 }
                }}>
                   <Button
                    shape="circle"
                    style={{ marginRight: 6 }}
                    size="small"
                    icon={<DeleteOutlined/>}
                  />
                </Popconfirm>
              </div>


            </div>

          </div>

        </div>

      </>
    )
  }

  const handleUpdate = async () => {
    setOrderLoader(true)
    try {

      let val = arr.map((item, index) => {
        return {
          id: item._id,
          order: index + 1
        }

      })
      // update val
      let x = await updateOrderAction(val)
      if (!x.error) {
        message.success('Order Updated Successfully')
        getDataFnx()
      }
      setOrderLoader(false)

    } catch {
      message.error('Error Occur On Order Updated ')
      setOrderLoader(false)

    }


  }

  return (
    <PageHeaderWrapper title={`All Teams Members (${total})`}>
      <Spin spinning={loader}>
        <Card
          bordered={true}
          extra={
            <div>
              <Button
                type="primary"
                style={{ marginRight: 10 }}
                loading={orderLoader}
                onClick={handleUpdate}
              >
                Update Team Order
              </Button>
              <Button
                type="primary"
                icon={<PlusOutlined/>}
                onClick={() => {
                  setOpen(true)
                  setKey(new Date())
                  setTeamId(null)
                }}>
                Add New Team Member
              </Button>

            </div>
          }
        >

          <DraggableList
            itemKey="name"
            template={PlanetItem}
            list={arr}
            onMoveEnd={(newList) => {
              setArr(newList)
            }}
            container={() => (document.body)}
          />

        </Card>
      </Spin>


      {open &&
      <AddTeam
        key={key}
        id={teamId}
        onSubmit={() => {
          getDataFnx()
        }}
      />
      }

    </PageHeaderWrapper>
  )
}

export default AllTeam

// const PlanetItem = (props) => {
//
//   const { item, itemSelected, dragHandleProps, onEdit } = props
//
//   const scale = itemSelected * 0.05 + 1
//   const shadow = itemSelected * 15 + 1
//   const dragged = itemSelected !== 0
//   const bgcolor = itemSelected !== 0 ? '#dadada' : 'white'
//
//   const [open, setOpen] = useState(false)
//   const [teamId, setTeamId] = useState(null)
//   const [teamKey, setTeamKey] = useState(null)
//   return (<>
//       <div
//         style={{
//           transform: `scale(${scale})`,
//           backgroundColor: bgcolor,
//           boxShadow: `rgba(0, 0, 0, 0.3) 0px ${shadow}px ${2 * shadow}px 0px`
//         }}
//       >
//
//         <div {...dragHandleProps}
//              style={{
//                display: 'inline-block',
//                float: 'left',
//                // background: "blue",
//                width: '50px',
//                height: '20px',
//                padding: '20px'
//              }}
//         >
//           <DragOutlined/>
//         </div>
//         <div>
//
//           <div className={style.container}>
//             <div>
//               <Image src={item.imgUrl} className={style.img}></Image>
//             </div>
//             <div>
//
//               <p className={style.name}>
//                 {item.name}
//               </p>
//               <p className={style.designation}>
//                 {item.designation}
//               </p>
//               <p className={style.phone}>
//                 {item.phoneNumber}
//               </p>
//
//             </div>
//
//             <div style={{ marginLeft: 'auto' }}>
//               <Tooltip title="Edit Team">
//                 <Button
//                   shape="circle"
//                   style={{ marginRight: 6 }}
//                   size="small"
//                   onClick={() => {
//                     setTeamId(item._id)
//                     setOpen(true)
//                     setTeamKey(Date.now().toString())
//                   }}
//                   icon={<EditOutlined/>}
//                 />
//               </Tooltip>
//             </div>
//
//
//           </div>
//
//         </div>
//
//       </div>
//
//       {open &&
//       <AddTeam
//         key={teamKey}
//         id={teamId}
//         onSubmit={() => {
//
//         }}
//       />
//       }
//
//     </>
//   )
// }
  
