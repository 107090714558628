import React, { useEffect, useState, useCallback, useRef } from 'react'
import { Form, Button, Card, notification, Space, Tooltip, Spin, InputNumber, Input, Upload, Select } from 'antd'
import { PlusOutlined, MinusCircleOutlined, UploadOutlined } from '@ant-design/icons'
import GetAllFormFields from '../../../components/_utils/formUtils'
import { addPropertyAction, deleteFileAction, listPropertyAction, updatePropertyAction } from '../actions'
import { listAreaAction } from '../../area/actions'
import { listDeveloperAction } from '../../developer/actions'
import { useDispatch } from 'react-redux'
import { API_URL, handlePreview, projectType, googleApiKey } from '../../../request'
import TextArea from 'antd/lib/input/TextArea'
import MapPicker from 'react-google-map-picker'
import Autocomplete from 'react-google-autocomplete'
import styles from './styles.less'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete'

const DefaultLocation = { lat: 25.2048493, lng: 55.2707828 }
const DefaultZoom = 14
const layoutOptions = [
  { label: 'Layout 1', value: '1' },
  { label: 'Layout 2', value: '2' }
]
const type = 'DragableUploadList'
const DragableUploadListItem = ({ originNode, moveRow, file, fileList }) => {
  const ref = useRef(null)
  const index = fileList.indexOf(file)
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {}
      if (dragIndex === index) {
        return {}
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
      }
    },
    drop: (item) => {
      moveRow(item.index, index)
    }
  })
  const [, drag] = useDrag({
    type,
    item: {
      index
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })
  drop(drag(ref))
  const errorNode = <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>
  return (
    <div
      ref={ref}
      className={`ant-upload-draggable-list-item ${isOver ? dropClassName : ''}`}
      style={{
        cursor: 'move'
      }}
    >
      {file.status === 'error' ? errorNode : originNode}
    </div>
  )
}

const AddProperty = props => {
  const [loader, setLoader] = useState(true)
  const [areaList, setAreaList] = useState([])
  const [developerList, setDeveloperList] = useState([])
  const [id, setId] = useState()
  const [cloneId, setCloneId] = useState(null)
  const [isRendered, setIsRendered] = useState(false)
  const [fileList, setFileList] = useState([])
  const [form] = Form.useForm()
  let { resetFields, setFieldsValue, getFieldValue } = form
  const { open, onClose, onSubmit } = props
  const [toggle, setToggle] = useState(false)
  const [address, setAddress] = useState('')
  const [termToggle, setTermToggle] = useState({
    completedIn23: false,
    forInvestment: false,
    newLaunch: false
  })
  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation)
  const [zoom, setZoom] = useState(DefaultZoom)

  const handleChangeLocation = (lat, lng) => {
    setDefaultLocation({ lat: lat, lng: lng })
  }

  const handleChangeZoom = (newZoom) => {
    setZoom(newZoom)
  }

  const onFinish = async values => {

    values.locationCoordinates = {
      type: 'Point',
      coordinates: [defaultLocation && defaultLocation.lat, defaultLocation && defaultLocation.lng]
    }

    // console.log(values, 'glrtgkltgklthktl')
    let x = null

    if (values && values.paymentPlan && values.paymentPlan.length) {
      values.paymentPlan = _.each(values.paymentPlan, (item, key) => {
        item.id = key + 1
      })
    }
    values.gallery = fileList
    setLoader(true)
    if (id) {
      values._id = id
      x = await updatePropertyAction(values)
      setLoader(false)

    } else {
      x = await addPropertyAction(values)
      setLoader(false)
      setTermToggle({
        completedIn23: false,
        forInvestment: false,
        newLaunch: false
      })
      setToggle(false)
    }

    if (!x.error) {
      notification.success({
        message: x.message
      })
      if (!id) {
        resetFields()
        setFileList([])
      }
      onSubmit()
      onClose()

    } else {
      notification.error({
        message: x.message ? x.message : 'Error Saving'
      })
    }
  }

  const onFinishFailed = errorInfo => {
    return notification.error({
      message: 'Please Fill All Required Fields'
    })
  }
  const fetchAreas = async () => {
    let { data } = await listAreaAction({ results: 500 })
    setAreaList(data)
  }
  const fetchDevelopers = async () => {
    let { data } = await listDeveloperAction({ results: 500 })
    setDeveloperList(data)
  }

  const deleteFile = async (file) => {
    if (file && !file.url) await deleteFileAction({ file, location: './public/property/' })
  }

  useEffect(() => {
    fetchAreas()
    fetchDevelopers()
  }, [])

  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search)
    let id = searchParams.get('id')
    setId(id)
    let cloneId = searchParams.get('cloneId')
    setCloneId(cloneId)

  }, [])

  useEffect(async () => {

    if (id) {
      setLoader(true)
      let { data } = await listPropertyAction({ _id: id })
      if (data && data.length) {
        let agent = data[0]
        if (agent && agent.locationCoordinates && agent.locationCoordinates.coordinates &&
          agent.locationCoordinates.coordinates.length) {
          setDefaultLocation({
            lat: agent.locationCoordinates.coordinates[0],
            lng: agent.locationCoordinates.coordinates[1]
          })

        }
        setFieldsValue({ ...agent })
        setFieldsValue({ developerId: agent && agent.developerId && agent.developerId._id })
        setToggle(agent && agent.onHomePage)
        setTermToggle({
          completedIn23: agent.completedIn23,
          forInvestment: agent.forInvestment,
          newLaunch: agent.newLaunch
        })
        setFileList(agent?.gallery)
        setLoader(false)
      } else {
        setLoader(false)
      }

    } else {
      setLoader(false)
    }
  }, [id])

  useEffect(async () => {

    if (cloneId) {
      setLoader(true)
      let { data } = await listPropertyAction({ _id: id })
      if (data && data.length) {
        let agent = data[0]
        if (agent && agent.locationCoordinates && agent.locationCoordinates.coordinates &&
          agent.locationCoordinates.coordinates.length) {
          setDefaultLocation({
            lat: agent.locationCoordinates.coordinates[0],
            lng: agent.locationCoordinates.coordinates[1]
          })

        }

        agent.projectName = agent.projectName + '  Copied'
        delete agent.photos
        delete agent.brochure
        delete agent.gallery
        delete agent.gallery

        delete agent.block1?.image
        delete agent.block2?.image
        delete agent.block3?.image
        delete agent._id

        // console.log(agent, "agent")

        setFieldsValue({ ...agent })
        setFieldsValue({ developerId: agent && agent.developerId && agent.developerId._id })
        setToggle(agent && agent.onHomePage)
        setTermToggle({
          completedIn23: agent.completedIn23,
          forInvestment: agent.forInvestment,
          newLaunch: agent.newLaunch
        })
        setLoader(false)
      }
      setLoader(false)

    }

  }, [cloneId])

  const handleChange = address => {
    setAddress(address)
  }

  const handleSelect = async (selected, placeid, test) => {
    form.setFieldsValue({ locationName: selected })
    const results = await geocodeByAddress(selected)
    const latLng = await getLatLng(results[0])
    setDefaultLocation(latLng)
  }

  const subInputTypes1 = {
    fields: [

      {
        label: 'Master Image',
        key: 'photos',
        required: true,
        type: 'file',
        limit: 4,
        customProps: {
          onPreview: (file) => {
            handlePreview(file)
          },
          onRemove: (file) => {
            deleteFile(file)
          },
          uploadUrl: `${API_URL}/propertiesUploader`
        }
      },

      {
        label: 'Project',
        key: 'projectName',
        required: true
      },
      {
        label: 'Description',
        key: 'description',
        type: 'textarea'

      },
      {
        label: 'Location',
        key: 'location',
        required: true
      },
      {
        label: 'Area',
        key: 'area',
        required: true
      },
      {
        label: 'Handover',
        key: 'handoverQuarter',
        required: false
      },
      {
        label: 'On Home Page',
        key: 'onHomePage',
        type: 'switch',
        customProps: {
          checked: toggle
        },
        onChange: (val) => {
          setToggle(val)
          form.setFieldsValue({ onHomePage: val })
        }
      },
      {
        label: 'Completed In 2023',
        key: 'completedIn23',
        type: 'switch',
        customProps: {
          checked: termToggle.completedIn23
        },
        onChange: (val) => {
          setTermToggle(s => ({ ...s, completedIn23: val }))
          form.setFieldsValue({ completedIn23: val })
        }
      },
      {
        label: 'For Investment',
        key: 'forInvestment',
        type: 'switch',
        customProps: {
          checked: termToggle.forInvestment
        },
        onChange: (val) => {
          setTermToggle(s => ({ ...s, forInvestment: val }))
          form.setFieldsValue({ forInvestment: valforInvestment })
        }
      },
      {
        label: 'New Launch',
        key: 'newLaunch',
        type: 'switch',
        customProps: {
          checked: termToggle.newLaunch
        },
        onChange: (val) => {
          setTermToggle(s => ({ ...s, newLaunch: val }))
          form.setFieldsValue({ newLaunch: val })
        }
      },
      {
        label: 'Payment Plan',
        key: 'paymentPlanDetails',
        required: false
      },
      {
        label: 'Brochure',
        key: 'brochure',
        //required: true,
        type: 'file',
        limit: 1,
        customProps: {
          onPreview: (file) => {
            handlePreview(file)
          },
          onRemove: (file) => {
            deleteFile(file)
          },
          uploadUrl: `${API_URL}/propertiesUploader`

        }
      },
      {
        label: 'Start From',
        key: 'startFrom',
        required: false,
        type: 'number'
      },
      {
        label: 'Developer Name',
        key: 'developerId',
        required: true,
        type: 'select',
        keyAccessor: x => x._id,
        valueAccessor: x => x.developerTitle,
        options: developerList,
        showSearch: true
      },
      {
        label: 'Project Type',
        key: 'projectType',
        required: false,
        type: 'select',
        mode: 'multiple',
        options: _.map(projectType, (item) => {
          return { value: item, id: item }
        })
      },
      {
        label: 'Layout',
        key: 'layout',
        required: false
      }
      // {
      //   label: 'Gallery',
      //   key: 'gallery',
      //   required: true,
      //   type: 'file',
      //   limit: 40,
      //   customProps: {
      //     onPreview: (file) => {
      //       handlePreview(file)
      //     },
      //     onRemove: (file) => {
      //       deleteFile(file)
      //     },
      //     uploadUrl: `${API_URL}/propertiesUploader`
      //
      //   }
      // }
    ]
  }
  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = fileList[dragIndex]
      setFileList(
        update(fileList, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow]
          ]
        })
      )
    },
    [fileList]
  )

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsRendered(true)
    }, 2000)
    return () => clearTimeout(timeoutId)
  }, [])
  return (<Card bordered={false}>
    <Spin spinning={loader}>
      <div>
        <Form
          form={form}
          name='basic'
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            paymentPlan: [{}]
          }}
        >

          <GetAllFormFields inputSchema={subInputTypes1}/>
          <Form.Item label={'Gallery'} name={'gallery'} required={true}>

            <DndProvider backend={HTML5Backend}>
              <Upload
                onPreview={(file) => {
                  handlePreview(file)
                }}
                action={`${API_URL}/propertiesUploader`}
                fileList={fileList}
                onChange={v => {
                  if (v.fileList) {
                    setFileList(v.fileList)
                  }
                }}
                onRemove={async (v) => {
                  deleteFile(v)
                }}
                //listType="picture-card"
                itemRender={(originNode, file, currFileList) => (
                  <DragableUploadListItem
                    originNode={originNode}
                    file={file}
                    fileList={currFileList}
                    moveRow={moveRow}
                  />
                )}
              >
                <Button icon={<UploadOutlined/>}>Select Image</Button>
              </Upload>

            </DndProvider>


          </Form.Item>

          <Form.Item label={'Payment Plan'}>
            <Form.List name='paymentPlan' initialValues={{
              paymentPlan: [{ description: '', percentage: '' }]
            }}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Space
                      key={key}
                      style={{ display: 'flex', marginBottom: 8 }}
                      align='baseline'>
                      <Form.Item
                        {...restField}
                        label={'Description'}
                        name={[name, 'description']}
                        fieldKey={[fieldKey, 'description']}
                      >
                        <Input placeholder='Description'/>
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label={'Percentage'}
                        name={[name, 'percentage']}
                        fieldKey={[fieldKey, 'percentage']}
                      >
                        <Input placeholder='Percentage'/>
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)}/>
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type='dashed'
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined/>}>
                      Add Payment Plan
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>

          {/* ====== bloack start */}
          <Form.Item label='Block 1' name='block1'>
            <Input.Group>
              <Form.Item name={['block1', 'title']}>
                <Input placeholder='Title'/>
              </Form.Item>
              <Form.Item name={['block1', 'body']}>
                <TextArea placeholder='Body'/>
              </Form.Item>
              <Space>
                <Form.Item name={['block1', 'btnText']}>
                  <Input placeholder='Button Text'/>
                </Form.Item>
                <Form.Item name={['block1', 'btnLink']}>
                  <Input placeholder='Button Link'/>
                </Form.Item>
                <Form.Item name={['block1', 'layoutType']}>
                  <Select options={layoutOptions} style={{ width: 100 }} placeholder={'Layout'}/>
                </Form.Item>
              </Space>
              <Form.Item name={['block1', 'image']} valuePropName='fileList' getValueFromEvent={(e) => e && e.fileList}>
                <Upload maxCount={1} showUploadList={true}
                        action={`${API_URL}/propertiesUploader`}
                        onPreview={(file) => {
                          handlePreview(file)
                        }}
                        onRemove={(file) => {
                          deleteFile(file)
                        }}


                >
                  <Button icon={<UploadOutlined/>}>Select Image</Button>
                </Upload>
              </Form.Item>
              <Upload/>
            </Input.Group>
          </Form.Item>

          <Form.Item label='Block 2' name='block2'>
            <Input.Group>
              <Form.Item name={['block2', 'title']}>
                <Input placeholder='Title'/>
              </Form.Item>
              <Form.Item name={['block2', 'body']}>
                <TextArea placeholder='Body'/>
              </Form.Item>
              <Space>
                <Form.Item name={['block2', 'btnText']}>
                  <Input placeholder='Button Text'/>
                </Form.Item>
                <Form.Item name={['block2', 'btnLink']}>
                  <Input placeholder='Button Link'/>
                </Form.Item>
                <Form.Item name={['block2', 'layoutType']}>
                  <Select options={layoutOptions} style={{ width: 100 }} placeholder={'Layout'}/>
                </Form.Item>
              </Space>
              <Form.Item name={['block2', 'image']} valuePropName='fileList' getValueFromEvent={(e) => e && e.fileList}>
                <Upload maxCount={1} showUploadList={true} action={`${API_URL}/propertiesUploader`}
                        onPreview={(file) => {
                          handlePreview(file)
                        }}
                        onRemove={(file) => {
                          deleteFile(file)
                        }}
                >
                  <Button icon={<UploadOutlined/>}>Select Image</Button>
                </Upload>
              </Form.Item>
              <Upload/>
            </Input.Group>
          </Form.Item>

          <Form.Item label='Block 3' name='block3'>
            <Input.Group>
              <Form.Item name={['block3', 'title']}>
                <Input placeholder='Title'/>
              </Form.Item>
              <Form.Item name={['block3', 'body']}>
                <TextArea placeholder='Body'/>
              </Form.Item>
              <Space>
                <Form.Item name={['block3', 'btnText']}>
                  <Input placeholder='Button Text'/>
                </Form.Item>
                <Form.Item name={['block3', 'btnLink']}>
                  <Input placeholder='Button Link'/>
                </Form.Item>
                <Form.Item name={['block3', 'layoutType']}>
                  <Select options={layoutOptions} style={{ width: 100 }} placeholder={'Layout'}/>
                </Form.Item>
              </Space>
              <Form.Item name={['block3', 'image']} valuePropName='fileList' getValueFromEvent={(e) => e && e.fileList}>
                <Upload maxCount={1}
                        showUploadList={true}
                        action={`${API_URL}/propertiesUploader`}
                        onPreview={(file) => {
                          handlePreview(file)
                        }}
                        onRemove={(file) => {
                          deleteFile(file)
                        }}
                >
                  <Button icon={<UploadOutlined/>}>Select Image</Button>
                </Upload>
              </Form.Item>
              <Upload/>
            </Input.Group>
          </Form.Item>


          {/* ======= blaock end */}

          <Form.Item label={'Search Location'} name={'locationName'}>
            {/*<Autocomplete*/}
            {/*  className={styles.autoButton}*/}
            {/*  apiKey={'AIzaSyBEKNUu8AmsB636O5GG-lj0hgHuEVjDPbQ'}*/}
            {/*  types={['regions', 'places','cities']}*/}
            {/*  onPlaceSelected={(place) => {*/}
            {/*    form.setFieldsValue({ locationName: place && place.formatted_address })*/}
            {/*    const lat = place.geometry.location.lat()*/}
            {/*    const lng = place.geometry.location.lng()*/}
            {/*    setDefaultLocation({ lat: lat, lng: lng })*/}
            {/*    console.log(`Latitude: ${lat}, Longitude: ${lng}`)*/}
            {/*  }}*/}
            {/*/>*/}


            <PlacesAutocomplete
              searchOptions={
                { LocationBias: 'IP_BIAS' }
              }
              value={address} onChange={setAddress} onSelect={handleSelect}>
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                  <input {...getInputProps({ placeholder: 'Type address', className: styles.autoButton })} />
                  <div>
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const style = {
                        backgroundColor: suggestion.active ? '#41b6e6' : '#fff'
                      }
                      return (
                        <div {...getSuggestionItemProps(suggestion, { style })} key={suggestion.placeId}>
                          {suggestion.description}
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>


          </Form.Item>

          <Form.Item label={'Location Coordinates'}>
            {isRendered ? <MapPicker
              defaultLocation={defaultLocation}
              zoom={zoom}
              mapTypeId='roadmap'
              style={{ height: '400px', marginBottom: '15px' }}
              onChangeLocation={handleChangeLocation}
              onChangeZoom={handleChangeZoom}
              apiKey={googleApiKey}/> : <Spin spinning={true}/>}

            {/*<label>Latitute : </label><input className={styles.inputBlock} type='text' value={defaultLocation?.lat}*/}
            {/*                                 disabled/>*/}
            {/*<label> Longitute : </label><input className={styles.inputBlock} type='text' value={defaultLocation?.lng}*/}
            {/*                                   disabled/>*/}
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 12, span: 16 }}>
            <Button type='primary' htmlType='submit' loading={loader}>
              {id ? 'Update' : 'Add'}
            </Button>
          </Form.Item>

        </Form>


      </div>
    </Spin>

  </Card>)
}

export default AddProperty

