import React, { Component, PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { debounce } from 'lodash-decorators'
import styles from './index.less'
import RightContent from './RightContent'
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons'

export default class GlobalHeader extends Component {

  state = {
    openDrawer: false
  }

  constructor(props) {
    super(props)

  }

  componentWillUnmount() {
    this.triggerResizeEvent.cancel()
  }

  @debounce(600)

  triggerResizeEvent() {
    // eslint-disable-line
    const event = document.createEvent('HTMLEvents')
    event.initEvent('resize', true, false)
    window.dispatchEvent(event)
  }

  toggle = () => {
    const { collapsed, onCollapse } = this.props
    onCollapse(!collapsed)
    this.triggerResizeEvent()
  }

  render() {
    const { collapsed, isMobile, logo } = this.props
    let user = { ...JSON.parse(localStorage.getItem('user')) }

    return (
      <div className={styles.header}>
        {isMobile && (
          <Link to="/" className={styles.logo} key="logo">
            <img src={logo} alt="logo" width="32" />
          </Link>
        )}
        <span className={styles.trigger} onClick={this.toggle}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </span>
        <RightContent {...this.props} />

      </div>
    )
  }

}
