import { addUserUrl, updateUserUrl } from '../api/index'
import axios from 'axios'
import { notification } from 'antd'
import { API_URL, getToken } from '../../../request'

export const addUserAction = async params => {
  try {
    console.log(params, 'add user parms')
    let { data } = await axios.post(
      addUserUrl(),
      { ...params },
      getToken()
    )


    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}

export const userListAction = async params => {

  try {
    let { data } = await axios.get(
      addUserUrl(), { params: { ...params }, ...getToken() }
    )

    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}
export const editUserAction = async params => {
  try {
    let { data } = await axios.put(
      updateUserUrl(params._id),
      { ...params },
      getToken()
    )

    // if (data.error) {
    //     notification.error({
    //         message: data.message || 'Updated Successfully'
    //     })
    // }
    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}

